<template>
  <div>
    <v-card outlined>
      <v-card-title>
        <v-btn-toggle
          dense
          mandatory
          v-model="conditionGroupItemLocal.logicalOperator"
          ><v-btn small value="all">ALLE</v-btn
          ><v-btn small value="any">MIND. EINE</v-btn></v-btn-toggle
        >
        <div class="caption font-weight-medium primary--text uppercase ml-2">
          Erfüllt
        </div>
        <v-spacer></v-spacer
        ><v-btn small depressed color="error" @click="deleteConditionGroup"
          ><v-icon small left>mdi-delete</v-icon>Löschen</v-btn
        ></v-card-title
      >
      <v-card-text>
        <div
          v-for="(condition, index) in conditionGroupItemLocal.conditions"
          :key="index"
        >
          <condition-builder-item
            v-if="condition.type === 'single'"
            :conditionItem="condition"
            :dataVariables="dataVariables"
            @deleteCondition="deleteCondition(index)"
          ></condition-builder-item>
          <condition-builder-group
            v-else-if="condition.type === 'group'"
            :conditionGroupItem="condition"
            @deleteConditionGroup="deleteConditionGroup()"
          ></condition-builder-group>
          <div
            v-if="index < conditionGroupItemLocal.conditions.length - 1"
            class="caption font-weight-medium primary--text uppercase mt-n5 mb-2"
          >
            Und:
          </div>
        </div>
      </v-card-text>
      <v-card-actions class=""
        ><v-btn text small class="ml-2 mb-2" @click="addCondition"
          ><v-icon left>mdi-plus</v-icon>Bedingung hinzufügen</v-btn
        >
        <v-btn text small class="mb-2" @click="addConditionGroup"
          ><v-icon left>mdi-plus</v-icon>Gruppe hinzufügen</v-btn
        ></v-card-actions
      >
    </v-card>
  </div>
</template>

<script>
import ConditionBuilderItem from "@/components/member-management/performance-targets/ConditionBuilderItem.vue";

export default {
  name: "condition-group-builder",
  props: {
    conditionGroupItem: {},
    dataVariables: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  components: {
    ConditionBuilderItem,
    "condition-builder-group": () => import("./ConditionBuilderGroup.vue"), // Rekursive Registrierung
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
      },
      conditions: [],
    };
  },
  model: {
    prop: "conditionGroupItem",
    event: "conditionGroupItemChange",
  },
  created() {},
  computed: {
    conditionGroupItemLocal: {
      get: function () {
        return this.conditionGroupItem;
      },
      set: function (value) {
        this.$emit("conditionGroupItemChange", value);
      },
    },
  },
  methods: {
    addCondition() {
      this.conditionGroupItemLocal.conditions.push({
        type: "single",
        rightArgument: {},
        leftArgument: {},
        operator: "==",
      });
    },
    addConditionGroup() {
      this.conditionGroupItemLocal.conditions.push({
        type: "group",
        logicalOperator: "all",
        conditions: [
          {
            type: "single",
            rightArgument: {},
            leftArgument: {},
            operator: "==",
          },
        ],
      });
    },
    deleteCondition(index) {
      this.conditionGroupItemLocal.conditions.splice(index, 1);
    },
    deleteConditionGroup() {
      this.$emit("deleteConditionGroup");
    },
  },
};
</script>
