<template>
  <div>
    <NavigationBar titel="Zielvorgaben" isPreview></NavigationBar>
    <section>
      <TippCard
        hintID="Terminplanung_Konfiguration_Allgemein_1_V1"
        text="Veranstaltungen basieren auf einer Veranstaltungsart. Jeder Veranstaltungsart sind verschiedene Funktionen und Parameter zugewiesen, die sich teilweise auch auf bestehende Veranstaltungen auswirken können. Auf abgeschlossene Veranstaltungen haben Änderungen keine Auswirkung."
      >
      </TippCard>
    </section>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <Subheader
                icon="mdi-bullseye-arrow"
                title="Zielvorgaben"
                :actions="actions"
              ></Subheader>
              <div class="mt-7">
                <v-data-iterator
                  :items="performanceTargets"
                  sort-by="sortKey"
                  no-data-text="Keine Zielvorgaben vorhanden"
                >
                  <template v-slot:default="props">
                    <v-row class="match-height">
                      <v-col
                        v-for="item in props.items"
                        :key="item.meta.id"
                        cols="12"
                      >
                        <v-card>
                          <!-- :to="{
                            name: 'member-management-performance-targets-details',
                            params: {
                              organizationId: $route.params.organizationId,
                              itemId: item.meta.id,
                            },
                          }" -->
                          <v-card-title>{{ item.title }}</v-card-title>
                          <v-card-subtitle>
                            <span>
                              <v-icon small>mdi-sort-numeric-ascending</v-icon>
                              {{ item.sortKey }}
                            </span>
                            Weitere Details
                          </v-card-subtitle>
                          <v-card-text>
                            <v-row class="match-height">
                              <v-col cols="12" sm="12" md="4">
                                <v-card outlined>
                                  <v-card-subtitle>
                                    Aktuelle Erfüllung
                                  </v-card-subtitle>
                                  <v-card-text>
                                    <v-row dense>
                                      <v-col cols="sm">
                                        <v-card
                                          class="pa-2"
                                          color="green lighten-4"
                                          dark
                                          :disabled="value == 0"
                                          outlined
                                        >
                                          <div
                                            class="font-weight-medium text-h5 text-center text--darken-4 green--text"
                                          >
                                            {{ value }}
                                          </div>
                                          <div
                                            class="font-weight-medium subtitle-2 text-center text--darken-4 green--text"
                                          >
                                            Erfüllt
                                          </div>
                                        </v-card>
                                      </v-col>
                                      <v-col cols="sm">
                                        <v-card
                                          class="pa-2"
                                          color="red lighten-4"
                                          dark
                                          :disabled="value == 0"
                                          outlined
                                        >
                                          <div
                                            class="font-weight-medium text-h5 text-center text--darken-4 red--text"
                                          >
                                            {{ value }}
                                          </div>
                                          <div
                                            class="font-weight-medium subtitle-2 text-center text--darken-4 red--text"
                                          >
                                            Nicht erfüllt
                                          </div>
                                        </v-card>
                                      </v-col>
                                    </v-row>
                                  </v-card-text>
                                </v-card>
                              </v-col>
                              <v-col cols="12" sm="12" md="8">
                                <v-card outlined>
                                  <v-card-subtitle>
                                    Erfüllung letzte 14 Tage (DD.MM.YYYY bis
                                    DD.MM.YYYY)
                                  </v-card-subtitle>
                                  <apexchart
                                    type="area"
                                    height="200"
                                    :options="chartOptions"
                                    :series="series"
                                  ></apexchart>
                                </v-card>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </template>
                </v-data-iterator>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
  </div>
</template>

<script>
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import TippCard from "@/components/_systemwide/Tipp_Card.vue";
import Subheader from "@/components/_systemwide/Subheader.vue";
import VueApexCharts from "vue-apexcharts";
import { MEMBER_MANAGEMENT } from "@/store/modules.js";
import { GET_PERFORMANCE_TARGETS } from "@/store/action-types.js";
import { MEMBER_MANAGEMENT_PERFORMANCE_TARGETS_create } from "@/data/permission-types.js";

export default {
  name: "member-management-performance-targets",
  components: {
    NavigationBar,
    TippCard,
    Subheader,
    apexchart: VueApexCharts,
  },
  data() {
    return {
      actions: [
        {
          title: "Erstellen",
          icon: "mdi-plus",
          permission: `${MEMBER_MANAGEMENT_PERFORMANCE_TARGETS_create}`,
          actionStyle: "textBtn",
          function: () => {
            this.$router.push({
              name: "member-management-performance-targets-new",
              params: {
                organizationId: this.$route.params.organizationId,
              },
            });
          },
        },
      ],

      value: 1,
      series: [
        {
          name: "Zielvorgabe erfüllt",
          data: [
            12, 27, 20, 17, 16, 18, 18, 17, 12, 27, 20, 17, 16, 18, 18, 17, 12,
            27, 20, 17, 16, 18, 18, 17,
          ],
        },
        {
          name: "Zielvorgabe nicht erfüllt",
          data: [
            2, 4, 10, 3, 12, 8, 9, 9, 2, 4, 10, 3, 12, 8, 9, 9, 2, 4, 10, 3, 12,
            8, 9, 9,
          ],
        },
      ],
      chartOptions: {
        chart: {
          type: "area",
          height: 350,
        },
        colors: ["#00E396", "#FF5252"],
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: "smooth",
        },
        fill: {
          type: "gradient",
          gradient: {
            opacityFrom: 0.6,
            opacityTo: 0.8,
          },
        },
        annotations: {
          xaxis: [
            {
              x: 19,
              borderColor: "#775DD0",
            },
            {
              x: 1,
              x2: 12,
              borderColor: "#000",
              fillColor: "#FEB019",
              label: {
                orientation: "horizontal",
                textAnchor: "start",
                text: "Cut-off ab 01.09.2024",
              },
            },
          ],
        },
        forecastDataPoints: {
          count: 5,
          fillOpacity: 0.5,
          strokeWidth: undefined,
          dashArray: 4,
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
        },
      },
    };
  },
  computed: {
    performanceTargets() {
      return this.$store.state.memberManagement.performanceTargets;
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.$store.dispatch(`${MEMBER_MANAGEMENT}${GET_PERFORMANCE_TARGETS}`, {
        organizationId: this.$route.params.organizationId,
      });
    },
  },
};
</script>
