<template>
  <div>
    <v-row dense>
      <v-col>
        <v-select
          dense
          :items="dataVariables"
          label="Variable"
          outlined
          v-model="conditionItemLocal.leftArgument"
          item-text="variable.id"
          item-value="variable.id"
          return-object
        ></v-select>
      </v-col>
      <v-col>
        <v-select
          v-model="conditionItemLocal.operator"
          dense
          label="Operator"
          outlined
        ></v-select
      ></v-col>
      <v-col
        ><v-text-field
          v-model="conditionItemLocal.rightArgument"
          dense
          label="Vergleichswert"
          outlined
        ></v-text-field
      ></v-col>
      <v-btn text depressed class="mt-2" color="error" @click="deleteCondition"
        ><v-icon>mdi-delete</v-icon></v-btn
      >
    </v-row>
  </div>
</template>

<script>
export default {
  name: "condition-builder-item",
  props: {
    conditionItem: {},
    dataVariables: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  components: {},
  data() {
    return {
      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
      },
    };
  },
  model: {
    prop: "conditionItem",
    event: "conditionItemChange",
  },
  created() {},
  computed: {
    conditionItemLocal: {
      get: function () {
        return this.conditionItem;
      },
      set: function (value) {
        this.$emit("conditionItemChange", value);
      },
    },
  },
  methods: {
    deleteCondition() {
      this.$emit("deleteCondition");
    },
  },
};
</script>
