<template>
  <div>
    <NavigationBar
      titel="Zielvorgabe anlegen"
      showBackBtn
      :actions="actions"
    ></NavigationBar>
    <section>
      <v-form
        @submit.prevent="createItem"
        ref="form"
        v-model="helpers.formIsValid"
      >
        <performance-target-editor
          v-model="performanceTarget"
          mode="new"
        ></performance-target-editor>
      </v-form>
    </section>
  </div>
</template>

<script>
import { Timestamp } from "@/firebase";
import { MEMBER_MANAGEMENT } from "@/store/modules.js";
import { CREATE_PERFORMANCE_TARGET } from "@/store/action-types.js";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import PerformanceTargetEditor from "@/components/member-management/performance-targets/PerformanceTargetEditor.vue";

export default {
  name: "scheduling-config-tab-event-types-event-type-new",
  components: {
    NavigationBar,
    PerformanceTargetEditor,
  },
  data() {
    return {
      actions: [
        {
          title: "Erstellen",
          icon: "mdi-plus-circle",
          actionStyle: "textBtnSubmit",
          function: this.createItem,
        },
      ],
      performanceTarget: {
        title: "",
        shortTitle: "",
        description: "",
        sortKey: Number,
        isActive: true,
        conditions: { logicalOperator: "all", conditions: [] },
        applicableDivisionId: null,
        monitoringInterval: { value: null, unit: null },
        dataVariables: [],
        validFrom: { date: null, time: null },
        validUntil: { date: null, time: null },
      },
      helpers: {
        formIsValid: false,
      },
    };
  },
  computed: {
    newSortKey() {
      return this.$store.state.memberManagement.performanceTargets.length + 1;
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.performanceTarget.sortKey = this.newSortKey;
    },
    validate() {
      this.$refs.form.validate();
    },
    createItem() {
      this.validate();
      if (this.helpers.formIsValid) {
        this.$store.dispatch(
          `${MEMBER_MANAGEMENT}${CREATE_PERFORMANCE_TARGET}`,
          {
            organizationId: this.$route.params.organizationId,

            title: this.performanceTarget.title,
            shortTitle: this.performanceTarget.shortTitle,
            sortKey: this.performanceTarget.sortKey,
            isActive: this.performanceTarget.isActive,
            validFrom: {
              timestamp: this.convertToTimestamp(
                this.performanceTarget.validFrom.date,
                this.performanceTarget.validFrom.time
              ),
            },
            validUntil: {
              timestamp: this.convertToTimestamp(
                this.performanceTarget.validUntil.date,
                this.performanceTarget.validUntil.time
              ),
            },
            applicableDivisionId: this.performanceTarget.applicableDivisionId,
            monitoringInterval: {
              unit: this.performanceTarget.monitoringInterval.unit,
              value: this.performanceTarget.monitoringInterval.value,
            },
            conditions: {
              logicalOperator:
                this.performanceTarget.conditions.logicalOperator,
              conditions: this.performanceTarget.conditions.conditions,
            },
          }
        );
      } else {
        alert("Prüfe Deine Eingabe und versuche es erneut.");
      }
    },
    convertToTimestamp(date, time) {
      const input = date + " " + time;
      return Timestamp.fromDate(new Date(input.replace(/-/g, "/")));
    },
  },
};
</script>

<style></style>
