<template>
  <v-container fluid>
    <v-row>
      <!-- Linke Spalte mit Breite 4 -->
      <v-col cols="4">
        <v-card class="left-card overflow-y-auto" min-height="140px">
          <v-card-title>Titel</v-card-title>
          <v-card-text class="">
            <!-- Inhalt der linken Spalte -->
            <div v-for="n in 200" :key="n">Item {{ n }}</div>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Rechte Spalte mit Breite 8 -->
      <v-col cols="8">
        <!-- Inhalt der rechten Spalte -->
        <div v-for="n in 50" :key="n">Content {{ n }}</div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "MyComponent",
};
</script>

<style scoped>
.left-card {
  max-height: 90vh;
}
</style>
