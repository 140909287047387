<template>
  <div>
    <template>
      <v-container>
        <v-row>
          <v-col cols="12">
            <Subheader icon="mdi-card-text" title="Allgemein"></Subheader>
            <div class="mt-7">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="3">
                        <v-text-field
                          v-model.number="performanceTargetLocal.sortKey"
                          type="number"
                          clearable
                          label="Sortierschlüssel"
                          :rules="[rules.required]"
                          outlined
                        ></v-text-field
                      ></v-col>
                      <v-col cols="12" sm="3">
                        <v-text-field
                          v-model.trim="performanceTargetLocal.shortTitle"
                          :counter="6"
                          clearable
                          label="Kurzbezeichnung"
                          :rules="[rules.required]"
                          outlined
                        ></v-text-field
                      ></v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model.trim="performanceTargetLocal.title"
                          :counter="32"
                          label="Bezeichnung"
                          required
                          :rules="[rules.required]"
                          outlined
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
          <v-col cols="12">
            <Subheader icon="mdi-cog" title="Einstellungen"></Subheader>
            <div class="mt-7">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col>
                        <v-switch
                          v-model="performanceTargetLocal.isActive"
                          dense
                          inset
                          label="Aktive Zielvorgabe"
                          class="mt-0"
                        ></v-switch>
                      </v-col>
                    </v-row>
                    <v-row>Verwendung zulässig ab:</v-row>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="performanceTargetLocal.validFrom.date"
                          type="date"
                          label="Startdatum"
                          hint="DD.MM.YYYY"
                          outlined
                          :rules="[rules.required, rules.startBeforeEnd]"
                          required
                          prepend-inner-icon="mdi-calendar-start"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="3">
                        <v-text-field
                          v-model="performanceTargetLocal.validFrom.time"
                          label="Startzeit"
                          hint="HH:MM"
                          :rules="[rules.required, rules.startBeforeEnd]"
                          outlined
                          type="time"
                          prepend-inner-icon="mdi-clock-start"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>Verwendung zulässig bis:</v-row>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="performanceTargetLocal.validUntil.date"
                          type="date"
                          label="Enddatum"
                          hint="DD.MM.YYYY"
                          outlined
                          :rules="[rules.required, rules.startBeforeEnd]"
                          required
                          prepend-inner-icon="mdi-calendar-end"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="3">
                        <v-text-field
                          v-model="performanceTargetLocal.validUntil.time"
                          label="Endzeit"
                          hint="HH:MM"
                          outlined
                          :rules="[rules.required, rules.startBeforeEnd]"
                          type="time"
                          prepend-inner-icon="mdi-clock-end"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>Anwendbar auf:</v-row>
                    <v-row>
                      <v-col cols="12" sm="6" md="6">
                        <v-select
                          v-model="performanceTargetLocal.applicableDivisionId"
                          :items="divisions"
                          label="Abteilung/Einheit"
                          outlined
                          :rules="[rules.required]"
                          item-value="meta.id"
                          item-text="title"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>Betrachtungszeitraum:</v-row>
                    <v-row>
                      <v-col cols="12" sm="6" md="6">
                        <v-select
                          v-model="
                            performanceTargetLocal.monitoringInterval.unit
                          "
                          :items="monitoringIntervalTypes"
                          label="Typ"
                          outlined
                          :rules="[rules.required]"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-model.number="
                            performanceTargetLocal.monitoringInterval.value
                          "
                          type="number"
                          label="Wert"
                          outlined
                          :rules="[rules.required]"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
          <v-col cols="12">
            <Subheader icon="mdi-variable" title="Variablen"></Subheader>
            <div class="mt-7">
              <v-card>
                <v-card-text
                  v-if="performanceTargetLocal.dataVariables.length > 0"
                >
                  <v-row
                    v-for="(
                      dataVariable, index
                    ) in performanceTargetLocal.dataVariables"
                    :key="index"
                    dense
                  >
                    <v-col cols="12" sm="2" md="2">
                      <v-text-field
                        v-model="dataVariable.variable.id"
                        dense
                        outlined
                        disabled
                        hide-details="auto"
                        label="ID"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="5" md="5">
                      <v-select
                        v-model="dataVariable.variable.type"
                        dense
                        label="Variable"
                        outlined
                        hide-details="auto"
                        :items="variableTypes"
                        return-object
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="4" md="4">
                      <v-select
                        dense
                        label="Wert"
                        outlined
                        hide-details="auto"
                      ></v-select
                    ></v-col>
                    <v-btn
                      text
                      depressed
                      class="mt-2"
                      color="error"
                      @click="deleteDataVariable(index)"
                      ><v-icon>mdi-delete</v-icon></v-btn
                    >
                  </v-row>
                </v-card-text>
                <v-card-actions
                  ><v-btn text small class="ml-2 my-2" @click="addDataVariable"
                    ><v-icon left>mdi-plus</v-icon>Variable hinzufügen</v-btn
                  >
                </v-card-actions>
              </v-card>
            </div>
          </v-col>
          <v-col cols="12">
            <Subheader
              icon="mdi-arrow-decision"
              title="Regeleditor"
            ></Subheader>
            <div class="mt-7">
              <condition-builder
                :conditionsData="performanceTargetLocal.conditions"
                :dataVariables="performanceTargetLocal.dataVariables"
              >
              </condition-builder>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </div>
</template>

<script>
import Subheader from "@/components/_systemwide/Subheader.vue";
import ConditionBuilder from "@/components/member-management/performance-targets/ConditionBuilder.vue";
import { MEMBER_MANAGEMENT } from "@/store/modules.js";
import { GET_DIVISIONS } from "@/store/action-types.js";
export default {
  name: "performance-target-editor",
  props: {
    performanceTarget: {},
    mode: {
      type: String,
      default: "new",
      required: true,
      validator: function (value) {
        return ["new", "edit"].indexOf(value) !== -1;
      },
    },
  },
  components: {
    Subheader,
    ConditionBuilder,
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
      },
      a: {
        type: "operation",
        leftArgument: {
          type: "variable",
          variableId: "var_id_8c296bf79c9da7afc1a2",
        },
        operation: "==",
        rightArgument: {
          type: "constant",
          value: { type: "ChoiceId", value: "choice_id_7e3eafc19ba46dd72c3d" },
        },
      },
      variableTypes: [
        {
          text: "Summe Anzahl Dienstteilnahme in min",
          value: "totalDutyTimeInMin",
        },
        {
          text: "Summe Anzahl Dienstteilnahme in min (abgerechnete Zeit)",
          value: "totalBillableDutyTimeInMin",
        },
        {
          text: "Summe Anzahl Funktionsausübung in min",
          value: "totalPositionTimeInMin",
        },
        {
          text: "Summe Anzahl Funktionsausübung in min (abgerechnete Zeit)",
          value: "totalBillablePositionTimeInMin",
        },
        {
          text: "Summe Anzahl Einsatzmittelbesetzung in min",
          value: "totalUnitTimeInMin",
        },
        {
          text: "Summe Anzahl Einsatzmittelbesetzung in min (abgerechnete Zeit)",
          value: "totalBillableUnitTimeInMin",
        },
      ],
      monitoringIntervalTypes: [
        { text: "Vorherige Tage", value: "days" },
        { text: "Laufende Wochen", value: "weeks" },
        { text: "Vorherige Wochen + aktuelle Woche", value: "weeks+current" },
        { text: "Laufende Monate", value: "months" },
        { text: "Vorherige Monate + aktueller Monat", value: "months+current" },
      ],
    };
  },
  model: {
    prop: "performanceTarget",
    event: "performanceTargetChange",
  },
  computed: {
    performanceTargetLocal: {
      get: function () {
        return this.performanceTarget;
      },
      set: function (value) {
        this.$emit("performanceTargetChange", value);
      },
    },
    divisions() {
      return this.$store.state.memberManagement.divisions;
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.$store.dispatch(`${MEMBER_MANAGEMENT}${GET_DIVISIONS}`, {
        organizationId: this.$route.params.organizationId,
      });
    },
    addDataVariable() {
      const nextDatavariableId =
        "var_id_" + Math.random().toString(36).substr(2, 9);
      this.performanceTargetLocal.dataVariables.push({
        variable: { id: nextDatavariableId, type: { text: null, id: null } },
        value: null,
      });
    },
    deleteDataVariable(index) {
      this.performanceTargetLocal.dataVariables.splice(index, 1);
    },
  },
};
</script>
